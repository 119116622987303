import React from 'react';

import {
  DatabaseOutlined,
  SolutionOutlined,
  UserOutlined,
} from '@/components/icons';
import { BaseMenuItemProps } from '@/components/menu';
import {
  PERMISSION_OBJ_AGENCY,
  PERMISSION_OBJ_AGENCY_MARKUP,
  PERMISSION_OBJ_APPLICATION_SETTINGS,
  PERMISSION_OBJ_APPROVER_CONFIGS,
  PERMISSION_OBJ_AREA,
  PERMISSION_OBJ_AUDIT,
  PERMISSION_OBJ_BREAKTIMES,
  PERMISSION_OBJ_BUILDING_CONFIGS,
  PERMISSION_OBJ_BUILDING_PAIRS,
  PERMISSION_OBJ_BUILDINGS,
  PERMISSION_OBJ_BUILDINGS_AREAS,
  PERMISSION_OBJ_COLOR,
  PERMISSION_OBJ_EMPLOYEES_SYNC_ERRORS,
  PERMISSION_OBJ_LANGUAGE,
  PERMISSION_OBJ_LINES,
  PERMISSION_OBJ_LOCKERS,
  PERMISSION_OBJ_MUSTER_STATIONS,
  PERMISSION_OBJ_PERMISSION,
  PERMISSION_OBJ_PERMISSION_OBJECT,
  PERMISSION_OBJ_POSITION,
  PERMISSION_OBJ_ROLE,
  PERMISSION_OBJ_SHIFT,
  PERMISSION_OBJ_SUPERVISOR,
  PERMISSION_READ,
} from '@/constants/permissions';

export interface IMenuItem extends BaseMenuItemProps {
  key: string;
  menuType?: string;
  parent: string;
  permissions: [string, number][];
  style?: any;
}

export const menuItems: IMenuItem[] = [
  {
    key: 'users',
    state: 'base-layout.users',
    icon: <UserOutlined />,
    messageId: 'menu.users',
    permissions: [[PERMISSION_OBJ_ROLE, PERMISSION_READ]],
    parent: 'root',
  },
  {
    key: 'roles',
    state: 'base-layout.roles',
    icon: <SolutionOutlined />,
    messageId: 'menu.roles',
    permissions: [[PERMISSION_OBJ_ROLE, PERMISSION_READ]],
    parent: 'root',
  },
  {
    key: 'permissions-objects',
    state: 'base-layout.permissions-objects',
    icon: <SolutionOutlined />,
    messageId: 'menu.permissions-objects',
    permissions: [
      [PERMISSION_OBJ_PERMISSION_OBJECT, PERMISSION_READ],
      [PERMISSION_OBJ_PERMISSION, PERMISSION_READ],
    ],
    parent: 'root',
  },
  {
    key: 'dictionaries',
    state: 'base-layout.dictionaries',
    // icon: <FontAwesomeIcon icon={faCogs} />,
    icon: <DatabaseOutlined />,

    messageId: 'menu.dictionaries',
    permissions: [[PERMISSION_OBJ_ROLE, PERMISSION_READ]],
    menuType: 'group',
    parent: 'root',
  },
  {
    key: 'agencies',
    state: 'base-layout.agencies',
    icon: <DatabaseOutlined />,
    messageId: 'menu.agencies',
    permissions: [[PERMISSION_OBJ_AGENCY, PERMISSION_READ]],
    parent: 'dictionaries',
  },

  {
    key: 'agenciesMarkup',
    state: 'base-layout.agenciesMarkup',
    icon: <DatabaseOutlined />,
    messageId: 'menu.agenciesMarkup',
    permissions: [[PERMISSION_OBJ_AGENCY_MARKUP, PERMISSION_READ]],
    parent: 'dictionaries',
  },
  {
    key: 'approverConfigs',
    state: 'base-layout.approverConfigs',
    icon: <DatabaseOutlined />,
    messageId: 'menu.approverConfigs',
    permissions: [[PERMISSION_OBJ_APPROVER_CONFIGS, PERMISSION_READ]],
    parent: 'dictionaries',
  },
  {
    key: 'areas',
    state: 'base-layout.areas',
    icon: <DatabaseOutlined />,
    messageId: 'menu.areas',
    permissions: [[PERMISSION_OBJ_AREA, PERMISSION_READ]],
    parent: 'dictionaries',
  },
  {
    key: 'buildingsAreas',
    state: 'base-layout.buildingsAreas',
    icon: <DatabaseOutlined />,
    messageId: 'menu.buildingsAreas',
    permissions: [[PERMISSION_OBJ_BUILDINGS_AREAS, PERMISSION_READ]],
    parent: 'dictionaries',
  },
  {
    key: 'buildingConfigs',
    state: 'base-layout.buildingConfig',
    icon: <DatabaseOutlined />,
    messageId: 'menu.buildingConfig',
    permissions: [[PERMISSION_OBJ_BUILDING_CONFIGS, PERMISSION_READ]],
    parent: 'dictionaries',
  },
  {
    key: 'breaktimes',
    state: 'base-layout.breaktimes',
    icon: <DatabaseOutlined />,
    messageId: 'menu.breaktimes',
    permissions: [[PERMISSION_OBJ_BREAKTIMES, PERMISSION_READ]],
    parent: 'dictionaries',
  },
  {
    key: 'buildingPairs',
    state: 'base-layout.buildingPairs',
    icon: <DatabaseOutlined />,
    messageId: 'menu.buildingPairs',
    permissions: [[PERMISSION_OBJ_BUILDING_PAIRS, PERMISSION_READ]],
    parent: 'dictionaries',
  },
  {
    key: 'buildings',
    state: 'base-layout.buildings',
    icon: <DatabaseOutlined />,
    messageId: 'menu.buildings',
    permissions: [[PERMISSION_OBJ_BUILDINGS, PERMISSION_READ]],
    parent: 'dictionaries',
  },
  {
    key: 'languages',
    state: 'base-layout.languages',
    icon: <DatabaseOutlined />,
    messageId: 'menu.languages',
    permissions: [[PERMISSION_OBJ_LANGUAGE, PERMISSION_READ]],
    parent: 'dictionaries',
  },
  {
    key: 'musterStations',
    state: 'base-layout.musterStations',
    icon: <DatabaseOutlined />,
    messageId: 'menu.musterStations',
    permissions: [[PERMISSION_OBJ_MUSTER_STATIONS, PERMISSION_READ]],
    parent: 'dictionaries',
  },
  {
    key: 'lines',
    state: 'base-layout.lines',
    icon: <DatabaseOutlined />,
    messageId: 'menu.lines',
    permissions: [[PERMISSION_OBJ_LINES, PERMISSION_READ]],
    parent: 'dictionaries',
  },
  {
    key: 'lockers',
    state: 'base-layout.lockers',
    icon: <DatabaseOutlined />,
    messageId: 'menu.lockers',
    permissions: [[PERMISSION_OBJ_LOCKERS, PERMISSION_READ]],
    parent: 'dictionaries',
  },
  {
    key: 'positions',
    state: 'base-layout.positions',
    icon: <DatabaseOutlined />,
    messageId: 'menu.positions',
    permissions: [[PERMISSION_OBJ_POSITION, PERMISSION_READ]],
    parent: 'dictionaries',
  },
  {
    key: 'shifts',
    state: 'base-layout.shifts',
    icon: <DatabaseOutlined />,
    messageId: 'menu.shifts',
    permissions: [[PERMISSION_OBJ_SHIFT, PERMISSION_READ]],
    parent: 'dictionaries',
  },
  {
    key: 'supervisors',
    state: 'base-layout.supervisors',
    icon: <DatabaseOutlined />,
    messageId: 'menu.supervisors',
    permissions: [[PERMISSION_OBJ_SUPERVISOR, PERMISSION_READ]],
    parent: 'dictionaries',
  },
  {
    key: 'colors',
    state: 'base-layout.colors',
    icon: <DatabaseOutlined />,
    messageId: 'menu.colors',
    permissions: [[PERMISSION_OBJ_COLOR, PERMISSION_READ]],
    parent: 'dictionaries',
  },
  {
    key: 'audit',
    state: 'base-layout.audit',
    icon: <DatabaseOutlined />,
    messageId: 'menu.audit',
    permissions: [[PERMISSION_OBJ_AUDIT, PERMISSION_READ]],
    parent: 'root',
  },
  {
    key: 'employeesSyncErrors',
    state: 'base-layout.employeesSyncErrors',
    icon: <DatabaseOutlined />,
    messageId: 'menu.employeesSyncErrors',
    permissions: [[PERMISSION_OBJ_EMPLOYEES_SYNC_ERRORS, PERMISSION_READ]],
    parent: 'root',
  },
  {
    key: 'applicationSettings',
    state: 'base-layout.applicationSettings',
    icon: <DatabaseOutlined />,
    messageId: 'menu.application-settings',
    permissions: [[PERMISSION_OBJ_APPLICATION_SETTINGS, PERMISSION_READ]],
    parent: 'root',
  },
];
