import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@/components/antd';

import { Drawer } from '@/components/drawer';
import { FormBuilderOptionItem, GenericForm, GenericFormProps } from '@/components/generic-form';
import { Loader } from '@/components/loader';
import { VoidFnType } from '@/types/common';
import { useDrawerForm } from '@/components/drawer-form/hooks';

export interface DrawerFormProps extends GenericFormProps {
  loaderCondition: boolean;
  title: string;
  initValues: any;
  formFields: FormBuilderOptionItem[];
  tailLayout?: object;
  onClose: VoidFnType;
  onSuccessFinish?: VoidFnType;
  onFinish?: (values: any) => void;
  visible?: boolean;
  useDisableSubmit?: boolean;
  width?: number;
  onChange?: (_) => void;
}

export const DrawerForm = ({
  title,
  initValues,
  formFields,
  onClose,
  onFinish,
  onChange,
  onSuccessFinish,
  loaderCondition,
  visible = true,
  useDisableSubmit = true,
  width = window.innerWidth - 250,
  ...props
}: DrawerFormProps) => {
  const { loadingSubmit, form, onStartSubmit, onFinishSubmit, onFieldsChange, disableSubmit } = useDrawerForm(
    onChange,
    props.form,
  );

  const DrawerFooter = ({ onFooterClose }: { onFooterClose: VoidFunction }) => {
    const disabled = useDisableSubmit ? disableSubmit : false;
    return (
      <div className="drawer-footer">
        <Button className="drawer-footer-button" shape="round" onClick={onFooterClose}>
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button onClick={form.submit} type="primary" shape="round" loading={loadingSubmit} disabled={disabled}>
          <FormattedMessage id="button.submit" />
        </Button>
      </div>
    );
  };

  const Footer = (onFooterClose: VoidFnType) =>
    props?.resourceController && <DrawerFooter onFooterClose={onFooterClose} />;

  return (
    <Drawer onClose={onClose} visible={visible} title={title} footer={Footer} width={width}>
      {loaderCondition ? (
        <Loader />
      ) : (
        <GenericForm
          {...props}
          formRef={form}
          initialValues={initValues}
          formFields={formFields}
          onFinish={onFinish}
          onSuccessFinish={onSuccessFinish || onClose}
          onStartSubmit={onStartSubmit}
          onFinishSubmit={onFinishSubmit}
          onFieldsChange={onFieldsChange}
        />
      )}
    </Drawer>
  );
};
