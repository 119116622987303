import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import { BooleanValueRender } from '@/components/table/renders';
import { PERMISSION_OBJ_BUILDINGS } from '@/constants/permissions';
import {
  buildingsStore,
  linesStore,
  lockersStore,
  musterStationsStore,
  musterStationCaptainsStore,
} from '@/stores';
import { getColumnBooleanFields, getColumnSearchFields } from '@/components/table/filters';

export const createState = 'base-layout.buildings.create';
export const editState = 'base-layout.buildings.edit';
export const bulkEditState = 'base-layout.buildings.bulk-edit';
export const listState = 'base-layout.buildings';

export const i18nPrefix = 'buildings';

export const mainUrl = '/buildings';

export const permissionObj = PERMISSION_OBJ_BUILDINGS;
export const store = buildingsStore;
export const apiMethods = api.buildings;

export const relatedStores = [lockersStore, linesStore, musterStationsStore, musterStationCaptainsStore];
export const keyForRelatedStores = 'buildingId';

const checkForDisabledDelete = (tableRowRecord: mpg.api.buildings.Building): boolean => {
  const [, ...relatedWithoutLockersStore] = relatedStores;

  const emptyLockers = lockersStore.items
    ?.some(({ buildingId, count }) => buildingId === tableRowRecord.id && count === 0);

  const includeBuilding = relatedWithoutLockersStore.some((relatedStore) => relatedStore?.items?.some((item) => item[keyForRelatedStores] === tableRowRecord.id));

  return !emptyLockers && includeBuilding;
};

const disabledTitle = 'You cannot remove building with related dictionaries';

// tslint:disable-next-line:no-namespace
declare namespace Intl {
  type Key = 'calendar' | 'collation' | 'currency' | 'numberingSystem' | 'timeZone' | 'unit';

  function supportedValuesOf(input: Key): string[];
}

const createInitValues = {
  enableShiftPlanning: false,
};

export const fields = [
  {
    name: 'name',
    componentName: 'input',
    params: {
      i18nLabel: 'buildings.name',
    },
    rules: [{ required: true }],
  },
  {
    name: 'enableShiftPlanning',
    componentName: 'switch',
    params: {
      i18nLabel: 'buildings.enableShiftPlanning',
    },
    rules: [
      { required: true },
    ],
  },
  {
    name: 'timezone',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'buildings.timezone',
      description: <FormattedMessage id="buildings.timezone-helper-text" />,
      options: Intl.supportedValuesOf('timeZone').map((timeZone: string) => ({
        label: timeZone,
        value: timeZone,
      })),
    },
    rules: [
      { required: true },
    ],
  },
];

export const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="buildings.name" />,
    dataIndex: 'name',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="buildings.enableShiftPlanning" />,
    dataIndex: 'enableShiftPlanning',
    ...getColumnBooleanFields(),
    sorter: true,
    render: BooleanValueRender,
  },
  {
    title: <FormattedMessage id="buildings.timezone" />,
    dataIndex: 'timezone',
    ...getColumnSearchFields(),
    sorter: true,
  },
];

export default {
  listState,
  createState,
  bulkEditState,
  editState,
  i18nPrefix,
  permissionObj,
  store,
  apiMethods,
  columns,
  fields,
  mainUrl,
  relatedStores,
  keyForRelatedStores,
  createInitValues,
  disabledDeleteProps: {
    checkForDisabledDelete,
    disabledTitle,
  },
};
